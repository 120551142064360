.modal.placeholder {
    .bp-box.is-price {
        height: 100%;
        position: relative;
        margin-bottom: 0;

        header, main > div {
            justify-content: space-between;
            display: flex;
        }

        header {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        main {
            height: 100%;
            padding: 41px 0 5px;
            & > div {
                padding-left: 10px;
                padding-right: 10px;
                & > div:nth-child(2) { display: flex; align-items: center; }
                input { width: 65px; }
            }
        }

        .input-price {
            margin-bottom: 2px;
        }
    }

    .bp-box.is-calculated-price {
        height: 100%;
        position: relative;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;

        .columns {
            margin: 0;
            align-items: center;
            width: 100%;

            .column {
                padding:0 ;
            }

            &:not(:last-child) {
                margin-bottom: 3px;
            }
        }

        header {
            .column:nth-child(3) {
                text-align: right;
            }
        }

        main {
            flex: 1;
            padding: 5px 10px;

            .level-left {
                > :first-child {
                    margin-right: 5px;
                }
            }
        }

        input { width: 55px; }

    }

    .crud-prices {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgba(#000, .5);
        z-index: 2;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px;

        .panel {
            font-size: $font-size;
            width: 100%;

            .panel-block {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            footer { display: block }
        }

        .columns .column {
            display: block
        }
    }


    .bp-box.is-freeplaces {
        header > div:first-child {
            align-items: center;
            flex-grow: 1;
            display: flex;
            button {
                margin-top: -1px;
                padding-left: 10px;
                i { color: $button_color; }
            }
        }

        main > div {
            padding-bottom: 2px;
            display: flex;
            & > div:nth-child(2) { flex-grow: 1; }
            button { padding: 0 7px 0 9px; }
        }

        input {
            width: 33px;
            margin: 0 7px 0 5px;
            text-align: center;
        }
        .select { margin: 0 5px 0 5px; }
    }


    .bp-box.is-extra {
        header {
            align-items: center;
            display: flex;
            button {
                margin-top: -1px;
                padding-left: 10px;
                i { color: $button_color; }
            }
        }

        main > div {
            align-items: center;
            display: flex;

            .btn-single-toggle {
                margin-right: 10px;
            }

            & > div:nth-child(2) {
                padding: 0 10px 2px 0;
                flex-grow: 1;
                input {
                    width: 100%;
                    text-align: left;
                }
            }
            & > div:nth-child(3) {
                margin-right: 10px;
                span {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
            & > div:nth-child(4) {
                align-items: center;
                display: flex;
                input:nth-child(3) { margin: 0 10px; }
            }
        }

        input {
            width: 33px;
            text-align: center;
        }
        .input-price {
            margin-bottom: 0;
            input { width: 52px; }
        }
        .buttons {
            margin: 0 10px 0;
            button {
                width: 25px;
                margin: 0;
                i { margin-right: 0; }
            }
        }
    }


    .is-package {
        margin-top: -12px;
        &:before {
            content: ' ';
            background-color: rgba(0, 0, 0, 0.1);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: opacity 0.2s ease-out;
            z-index: 1;
            pointer-events: none;
        }
        &.has-overlay {
            &:before {
                opacity: 1;
                pointer-events: auto;
            }

            & > div:nth-child(2) {
                position: relative;
                text-align: right;
                z-index: 1;
                & > button {
                    min-width: 145px;
                    margin-bottom: 20px;
                }
            }
        }


        .is-add-package, .is-add-request {
            background-color: white;
        }
        .package {
            text-align-last: center;
        }

        .is-add-package main {
            label {
                align-self: stretch;
                line-height: 37px;
            }
            td:nth-child(1) { width: 240px; }
            td:nth-child(2) {
                align-items: center;
                display: flex;
            }
        }

        .request-title {
            background-color: white;
            margin-right: 6px;
            padding: 0 7px;
            border: 1px solid $border-color;
            border-radius: $border-radius;
        }


        .is-package-list {
            td {
                &:nth-child(2) { width: 250px; }
                &:nth-child(3) { width: 150px; }
                &:nth-child(4) { width: 150px; }
                &:nth-child(5) { width: 35px; }
            }
        }
    }


    .is-moved-up {
        transform: translateY(-20px);
        text-align: right;
        display: block;
    }
}
