.is-dashboard {
    .has-edit-mode {
        position: relative;
        z-index: 2;
        &.is-edit-mode {
            z-index: 3;
        }

        &:hover button.is-edit-btn {
            display: inline-block;
        }
        button.is-edit-btn {
            width: 30px;
            height: 26px;
            position: absolute;
            top: 0px;
            right: -8px;
            padding: 0 3px;
            transition: none;
            z-index: 1;
            display: none;
            .material-icons {
                font-size: 14px;
            }
        }

        .bp-btn-save,
        .bp-btn-cancel {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 0;
            padding: 0 2px 0 0;
            border: 0 none;
            display: block;
            i {
                margin-right: 0;
            }
            &:hover {
                border: 0 none;
                i {
                    transform: scale(1.3);
                }
            }
        }
        .bp-btn-cancel {
            background-color: white;
            right: -22px;
            border-radius: $border-radius 0 0 $border-radius;
            i {
                color: $red;
            }
        }
        .bp-btn-save {
            background-color: $green;
            right: -52px;
            border-radius: 0 $border-radius $border-radius 0;
            i {
                color: white;
            }
        }

        .flatpickr-input[readonly] {
            width: 64px;
            transition: none;
        }

        .multiselect {
            z-index: 1;
            display: inline-block;
            width: 150px;
        }

        .input {
            z-index: 1;
        }
    }

    .info-row {
        padding: 0 15px;

        span {
            @include ellipsis();
            align-items: center;
            display: flex;
        }
    }

    & > main > div {
        margin-bottom: 45px;
    }

    .is-documents-section {
        .row.is-head,
        .pagination-info,
        .custom-page {
            display: none;
        }
    }

    .is-todo-section {
        .actions {
            margin-bottom: 15px;
            display: flex;
            flex-wrap: wrap;
            .select {
                width: 150px;
                margin: 0 10px;
            }
        }

        .todos {
            & > .level {
                justify-content: flex-end;
            }
            .bp-panel.is-empty {
                margin-bottom: 20px;
                padding: 20px 0;
            }
        }
    }

    .v-popover.is-booking-stand {
        .trigger {
            width: 100%;
        }
    }

    .pagination-info,
    .pagination + .custom-page {
        display: none;
    }
}

@media all and (max-width: 1700px) {
    .is-dashboard {
        width: 420px;
        position: absolute;
        top: 15px;
        right: -5px;
        z-index: 2;
        transition: transform 0.2s ease-out, opacity 0.2s ease-out;
        transform: translateX(420px);
        opacity: 0;
        display: none;

        & + .overlay {
            background-color: rgba(0, 0, 0, 0.2);
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transition: opacity 0.2s ease-out;
            opacity: 0;
            display: none;
        }

        &.is-active,
        &.is-active + .overlay {
            display: block;
        }
        &.is-visible,
        &.is-visible + .overlay {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

.bp-panel {
    background-color: $background-color;
    position: relative;
    padding: 31px 0 15px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    border-top: 2px solid $accent;
    // overflow: hidden; SELECT dropdown hidden

    .panel-actions {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $gray-light;
        visibility: hidden;

        width: 100%;
        z-index: 1;
        position: absolute;
        left: 0;
        top: 100%;
        opacity: 0;

        transition: 0.25s transform, 0.25s opacity;
        box-shadow: 0 -5px 10px rgba(black, 0.05);

        .v-popover {
            font-size: 11px;
            flex: 1 1 0;
            background: white;
            text-transform: uppercase;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        button,
        a {
            font-size: 11px;
            flex: 1 1 0;
            padding: 5px;
            background: white;
            text-transform: uppercase;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;

            &:not(:last-child) {
                border-right: 1px solid $gray-light;
            }
            &:hover {
                background: $background-color;
            }
            i {
                margin-right: 5px;
            }
        }
    }

    &.is-form {
        padding-bottom: 0;
        visibility: visible;
        opacity: 1;
        display: flex;
        flex-direction: column;

        .panel-content {
            flex: 1;
        }

        .panel-actions {
            position: static;
            opacity: 1;
            visibility: visible;
            margin-top: 31px;
            box-shadow: 0 0 0 rgba(black, 0);
            z-index: inherit;
        }
    }

    &.is-form {
        padding-bottom: 0;
        visibility: visible;
        opacity: 1;

        .panel-actions {
            position: static;
            opacity: 1;
            visibility: visible;
            margin-top: 31px;
            box-shadow: 0 0 0 rgba(black, 0);
        }
    }

    &.is-hover .panel-actions {
        transform: translateY(-100%);
        opacity: 1;
        visibility: visible;
    }

    .panel-content {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;

        ul {
            li {
                flex-wrap: nowrap;
                align-items: center;

                &.is-top {
                    align-items: flex-start;
                }

                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                i {
                    margin-right: 5px;
                }

                &.tags {
                    flex-wrap: wrap;

                    .tag {
                        margin-bottom: 0.5rem;
                    }
                }
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        .pell-content {
            ul {
                li {
                    display: list-item;
                }
            }
        }

        dl {
            dt {
                font-weight: bold;
                text-transform: uppercase;
            }

            dd {
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
    }

    &.has-header {
        padding-top: 0;
        background: white;

        &.is-grey-lightest {
            background: $gray-lightest
        }
    }



    .panel-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $gray-lighter;
        border-bottom: 1px solid $gray-light;
        margin-bottom: 15px;
        padding: 10px 15px;

        &.is-grey-lightest {
            background: $gray-lightest
        }

        > * {
            margin-bottom: 0;
        }
    }

    .panel-label {
        background: $accent;
        transition: 0.25s background;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 20px;
        border-top-right-radius: $border-radius;
        color: white;



        > i {
            padding-bottom: 1px;
        }

        * {
            color: inherit;
        }

        &.has-hover {
            cursor: pointer;

            &:hover {
                background: darken($accent, 5%);
            }
        }
    }

    .info-row {
        min-height: 31px;
        font-size: $font-size;
        cursor: default;
        align-items: center;
        display: flex;

        & i {
                min-width: 18px;
                margin-right: 7px;
                text-align: center;
        }

        .btn-single-toggle {
            margin-right: 5px;

            > i {
                    min-width: 0;
                    margin-right: 0;
                    color: $gray-dark;




                &.fa.fa-folder-open {
                    margin-top: 2px;
                    margin-left: 1px;
                }
            }
        }

        .bp-deadline {
            width: 100%;
            background-color: transparent;
            position: relative;
            margin-top: 0;
            padding-left: 0;
            align-items: center;
            cursor: pointer;
            display: flex;

            &::before {
                position: absolute;
                top: 10px;
                left: 34px;
            }

            span {
                width: 100%;
                height: 30px;
                background-color: white;
                padding-left: 6px;
                border: 1px solid $gray-light;
                transition: border-color 0.2s ease-out;
                color: $text-color;
            }
            &:hover span {
                border-color: $border-color;
            }
            &.is-red,
            &.is-yellow {
                span {
                    padding-left: 25px;
                }
            }
        }
    }

    &.has-two-columns {
        flex-wrap: wrap;
        display: flex;
        .info-row {
            width: 50%;
        }
    }
    &.is-gray {
        border-top: 2px solid $gray-dark;
        .panel-label {
            background: $gray-dark;
        }
    }

    &.is-add {
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.25s background;
        min-height: 150px;
        display: flex;
        flex: 1;

        &:hover {
            background: lighten($background-color, 5%);
        }

        > .material-icons,
        .panel-content > .material-icons {
            font-size: 36px;
        }

        .panel-actions {
            position: absolute;
            top: auto;
            bottom: 0;
            margin-top: 0;
            visibility: visible;
            opacity: 1;
        }
    }

    .pell {
        border-top: 0;
        box-shadow: 0 0 0 rgba(#000, 0);
        border: 0;

        &-actionbar {
            background: 0;
        }
    }

    &.is-flex {
        display: flex;
        flex-direction: column;

        .panel-content {
            flex: 1;
            display: flex;
            flex-direction: column;

            .content-grow {
                flex: 1;
            }
        }
    }
}

.bp-panel--multiple {
    display: flex;
    & > div {
        flex: 1;
    }
}
.row-label {
    width: 170px;
    display: block;
    text-align-last: left;
}
.bp-panel.is-contingent {
    position: relative;
    padding-bottom: 5px;

    & > button {
        position: absolute;
        top: 5px;
        left: 12px;
    }


    header,
    .row {
        padding: 3.5px 7.5px;
        display: flex;
        line-height: 28px;
        & > div {
            flex: 1;
            text-align: center;
            &:not(:last-child) { input {border-right: 0;} }
            align-self: center;
        }
        min-height: 37px;
    }

    header {
        margin-bottom: 10px;
        padding-top: 0;
        padding-bottom: 5px;
        border-bottom: 1px solid $gray-light;
        min-height: 34px;
    }

    input {
        text-align: center;
        width: 33px;

    }
}

.bp-panel.is-contact {
    margin-bottom: 25px;

    [class^="btn-"] {
        opacity: 0;
        transition: opacity 0.2s ease-out;
    }
    &:hover [class^="btn-"] {
        opacity: 1;
    }

    .name {
        min-height: 40px;
        position: relative;
        padding: 0 35px 10px 15px;
        span {
            padding-right: 20px;
            text-transform: uppercase;
            font-weight: 600;
        }
        .simple-menu {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }

    .is-bus-driver {
        position: relative;
        margin: 15px 15px 0;
        border-top: 1px solid $border-color;
        padding: 15px 0 0 30px;
        & > i {
            position: absolute;
            top: 21px;
            left: 0;
        }
        label {
            min-width: 95px;
            padding-bottom: 5px;
            line-height: 28px;
            display: inline-block;
        }
        input {
            width: 35px;
            text-align: center;
            &.is-half {
                width: 50%;
            }
        }
    }
}

.bp-panel.is-settings {
    min-height: 140px;

    .columns {
        .column {
            padding-bottom: 0;
            padding-top: 0.5rem;

            & > div {
                height: 30px;
            }

            .multiselect {
                &.is-blue {
                    .multiselect__single {
                        color: $currency-info-color;
                    }
                }
            }
        }
    }
}

.bp-panel.is-image {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.for-contingent-tooltip .tooltip-inner div {
    text-align: left;
    span {
        min-width: 110px;
        display: inline-block;
    }
    div:first-child {
        padding: 3px 5px;
    }
    div:last-child {
        padding: 3px 5px;
    }
}
