@import '../utilities/colors.scss';

/* General colors */
$text-color: $black;
$background-color:      $gray-lightest;
$background-semi-transparent: $blue-dark;
$text-disabled:         $gray;
$link-color:            $turqoise;
$border-color:          $gray;
$additonal-color:       $blue-light;
$currency-info-color:   $blue-light;

/*Multiselect colors */
$multiselect-select:  $ocean-green;
$multiselect-select-font:  white;
$multiselect-selected:  $gray;
$multiselect-selected-font:  $ocean-green;
$multiselect-deselect:  $red-light;
$multiselect-deselect-font: white;

/* Buttons */
$button-color:              $blue-dark;
$button-hover-color:        $blue-darker;
$button-border-color:       $gray-light;
$button-hover-border-color: $gray-dark;

/*Inputs */
$input-border-color:        $gray-light;
$input-hover-border-color:  $gray-dark;

/* Sizes */
$menu-width:    220px;
$header-height: 52px;
$border-radius: 2px;
$box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
$font-size: 12px;
$font-weight-normal: 400;
$font-family-primary: 'Open Sans';

/*Table */
$accent: $turqoise;
$row: $background-color;
$rowBorder: $gray-light;
$headRow: $gray-lighter;
$editingRow: $gray;
$rowZebra: #FBFBFB;
$rowHover: #E8F4FF;
$rowHeading: #eee;

//$subTableRow: #fef6ed;
//$subTableHeadRow: antiquewhite;
$subTableHeadRow: #74b2db5e;
$subTableRow: #74b2db26;


/*Deadlines */
$highUrgency: $red-light;
$middleUrgency: $yellow;

/* Robins Wunsch
    font-weight: 530;
    font-family: "Arial Rounded MT Bold", Arial, Helvetica, sans-serif;
 */
