.modal.new-email {
    header {
        background-color: white;
        text-align: left;
        display: block;
        .name {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .buttons { margin: 16px 0; }
    }

    .modal-content .close { min-width: 0; }

    &.ai-open {

        margin-right: 450px;

    }

    .modal-body {
        max-height: 80vh;
        height: auto;
        background-color: $background-color;

        & > div {
            @include box-with-top-color($bg-color: $background-color);
            margin-bottom: 20px;
            padding: 15px;
        }
        .new-email-modal-form { margin-bottom: 0; }
    }

    footer { display: none; }


    .last-icon { margin: 0 10px 0 -5px; }

    .multiselect {
        min-width: 630px;
        width: auto;
        display: inline-block;
        .multiselect__input { min-width: 222px; width: auto; }
    }
    .is-order-select { min-width: 730px; }


    .simple-box {
        padding: 10px 40px 10px 15px;
        font-size: $font-size;
        &.is-white { background-color: white; }

        & > i { min-width: 18px; }

        & > div:first-child { padding-bottom: 5px; }
        label {
            width: 90px;
            display: inline-block;
        }

        input {
            width: 580px;
            margin-left: 5px;
            vertical-align: middle;
        }
        .multiselect { margin-bottom: 5px; }
        .float-right {
            min-width: 190px;
            float: right;
            margin-top: -4px;
        }
        .buttons + input { vertical-align: top; }
        .buttons.has-addons { margin-bottom: 0; }
    }


    .requests-table {
        max-width: none;
        padding-left: 10px;
        padding-bottom: 5px;
        align-items: flex-start;
        line-height: 25px;
        display: flex;
        label {
            width: 170px;
            flex-shrink: 0;
        }

        & > div { flex-grow: 1; }
        input[type="checkbox"] { vertical-align: -3px; }
        .deadline-date[class*="is-"] { margin-top: 0; }

        header, main > div {
            padding: 2px 5px;
            display: flex;
            & > div:nth-child(1) { width:  25px; }
            & > div:nth-child(2) {
                flex-grow: 1;
                i { margin-right: 5px; }
            }
            & > div:nth-child(3) { width: 150px; }
            & > div:nth-child(4) { width: 100px; }
            & > div:nth-child(5) { width: 100px; }
            & > div:nth-child(6) { width: 130px; }
            & > div:nth-child(7) { width: 130px; }
            & > div:nth-child(8) { width:  95px; }
        }
        header {
            background-color: $gray-lighter;
            border: 1px solid $border-color;
            border-radius: $border-radius $border-radius 0 0;
            font-weight: 600;
            & > div:nth-child(1) { width: 27px; }
        }
        .row.is-headline { padding-left: 32px; }
        main {
            background-color: white;
            & > div {
                border: 1px solid $border-color;
                border-top: 0 none;
            }
        }
    }
}


.new-email-modal-form {
    @include box-with-top-color($bg-color: $background-color);
    position: relative;
    padding: 15px;
    font-size: $font-size;

    h3 {
        font-weight: 700;
        text-align: left;
    }

    .input-wrapper, .pell-wrapper {
        display: flex;
        label {
            min-width: 170px;
            line-height: 24px;
        }
    }

    .input-wrapper {
        width: 100%;
        //max-width: 640px;
        padding: 3px 10px;
        .multiselect {
            min-width: 0;
            width: 100%;
        }
        & > div:not(.multiselect) {
            width: 100%;
            justify-content: space-between;
            display: flex;
            .multiselect {
                margin: 0 10px;
                &:first-child { margin-left: 0; }
                &:last-child { margin-right: 0; }
            }
        }
    }

    .attachments{
        display: flex;
        label {
            min-width: 170px;
            line-height: 24px;
        }
        //width: 46%;
        //max-width: 640px;
        padding: 3px 10px;
    }
    .pell-wrapper {
        padding: 5px 0 5px 10px;
        & >  div { flex-grow: 1; }
    }
    .pell {
        background-color: white;
        margin-bottom: 20px;
        padding: 0 0 1px;
        border: 1px solid $border-color;
        border-radius: $border-radius;
    }

    .pell-content {
        min-height: 300px;
        padding: 0;
        resize: vertical;

        p {
            color: inherit;
            font-size: inherit;
        }
    }

    .pell-actionbar {
        background-color: transparent;
        padding: 0 10px;
    }

    .pell-button {
        min-width: 0;
        background-color: white;
    }

    & > button { min-width: 145px; }
    & > .button { margin-top: 20px; }

    .buttons-wrapper {
        padding-left: 180px;
        text-align: right;
        justify-content: space-between;
        display: flex;

        button.button.is-gray { background-color: $gray-lighter; }

        & > div {
            text-align: left;

            > button,
            > .email-attachments > button {
                margin-right: 20px;
                margin-bottom: 20px;
            }
        }

        .email-attachments {
            div.button {
                margin: 0 10px 10px 0;
                padding-top: 5px;
                & > i { margin-top: 0; }
            }
        }

        .btn-delete {
            position: relative;
            top: -2px;
            right: -8px;
        }
    }

    .multiselect .option__name {
        min-width: 160px;
        display: inline-block;
    }


    .attachments-modal {
        width: 100%;
        //background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        //top: 0;
        bottom: 85%;
        left: 0;
        z-index: 10;
        align-items: center;
        justify-content: center;
        display: flex;

        .panel {
            width: 95%;
        }
        .panel-block {
            border-radius: 0;
            font-size: $font-size;
            align-items: stretch;
            display: flex;
            & > button {
                width: 120px;
                position: relative;
                margin: 0 10px 0 0;
                padding: 7px 10px;
                border: 1px solid $gray-light;
                box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
                font-size: $font-size;
                transition: border-color 0.2s ease-out;
                &:hover { border-color: $accent; }
                i {
                    background-color: white;
                    position: absolute;
                    top: -10px;
                    right: -12px;
                    transform: scale(0);
                    transition: transform 0.2s ease-out;
                    color: $green;
                }
                &.is-selected {
                    border-color: $accent;
                    i { transform: scale(1); }
                }
            }
        }

        footer { display: block; }
    }
}
