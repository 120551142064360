// Color list
$color-navy-blue:#34495e;
$color-violet:#9b59b6;
$color-blue:#3498db;
$color-green:#62cb31;
$color-yellow:#ffb606;
$color-orange:#e67e22;
$color-red:#e74c3c;
$color-red-deep:#c0392b;


// Additional colors
$color-text: $text-color;
$color-bright: #f7f9fa;
$color-background: #f1f3f6;
$color-background-boxed: lighten($color-background, 3%);

// Additional variables
$main-transition: all 0.1s ease-out 0s;


// Import all styles

// Components
@import "components/modal";
@import "components/right_side_bar";
@import "components/checkbox_radio";
@import "components/loader";
@import "components/dropdown";
@import "components/panels";
@import "components/rating";
@import "components/tables";

// Plugins
@import "plugins/icheck";

// Utilities
@import "utilities/fonts";
