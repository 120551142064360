.statistic-number {
    text-align: center;

    &.current {
        font-size: 1rem;
    }
    &.historic {
        color: $grey-mid-dark;
        display: block;
        font-size: 0.75rem;
        margin-top: 0.25rem;
    }
    &.percentage {
        color: $grey-mid-dark;
        display: block;
        font-size: 0.75rem;
        margin-top: 0.25rem;
        .is-up {
            color: $ocean-green;
        }
        .is-down {
            color: $red-light;
        }
        i {
            margin-left: 5px;
        }
    }
}
