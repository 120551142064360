.simple-menu {
    position: relative;
    cursor: pointer;
    display: inline-block;
    font-size: 0;

    > i {
        color: $blue-dark;
        font-size: 16px;
    }

    & > button {
        width: 15px;
        height: 15px;
        font-size: 16px;
        color: $blue-dark;
        border-radius: 50%;
        text-align: center;
        line-height: 18px;
        cursor: pointer;
        display: inline-block;

        i {
            color: inherit;
        }


    }

    .simple-menu-content {
        background-color: $blue-dark;
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px 0;
        color: white;
        z-index: 3;

        button, a {
            width: 100%;
            background-color: transparent;
            padding: 4px 15px 4px 10px;
            border: 0 none;
            color: white;
            font-size: $font-size;
            font-weight: $font-weight-normal;
            text-align: left;
            align-items: center;
            white-space: nowrap;
            display: flex;

            &.is-success {
                background: $green;

                &:hover {
                    background: darken($green, 5%);
                }

                &[disabled] {
                    background: lighten($green, 20%)
                }
            }

            i {
                margin-right: 5px;
                color: white;
                line-height: 15px;

                &.material-icons {
                    font-size: 14px;
                }
            }
            &:hover, &.is-active {
                background-color: $text-color;
            }
        }

        hr {
            border-bottom: 1px dashed #fff;
            border-top: 0;
            margin-top: 5px;
            margin-bottom: 5px;
            background: 0;
            height: 0;
        }
    }

    .sub-menu {
        background-color: $blue-dark;
        position: absolute;
        min-height: 100%;
        top: 0;
        right: 99%;
        padding: 5px 0;
        overflow: hidden;
        transform-origin: top right;
        transform: scale(0);
        transition-timing-function: ease-out;
        transition: 0.35s;
        transition-delay: 0.35s;
    }
    .has-sub-menu:hover .sub-menu {
        transition-timing-function: ease-in;
        transition: 0.15s;
        transition-delay: 0.15s;
        transform: scale(1);
        button { transform: scale(1); }
    }

    &--export {
        .simple-menu-content {
            display: flex;
            align-items: center;
            padding: 7px;

            > span {
                font-size: $font-size;
                color: #fff;
                margin-right: 10px;
            }
        }
    }
}



.inside-page-navigation {
    height: 100%;
    background: white;
    position: relative;
    flex-grow: 1;
    display: flex;

    & > * {
        padding: 5px 0 0;
        border-right: 1px solid $gray-light;
        font-size: $font-size;
        font-weight: 600;
        text-align: center;
        flex-grow: 1;
        flex-basis: 0;
        font-size: $font-size;
        line-height: 42px;

        &.is-active, &:hover { background-color: $background-color; }

        &:nth-child(1)  i { font-size: 22px; }
        &:nth-child(2)  i { font-size: 21px; }
        &:nth-child(3)  i { font-size: 22px; }
        &:nth-child(4)  i { font-size: 22px; }
        &:nth-child(5)  i { font-size: 22px; }
        &:nth-child(6)  i { font-size: 22px; }
        &:nth-child(7)  i { font-size: 22px; }
        &:nth-child(8)  i { font-size: 22px; }
        &:nth-child(9)  i { font-size: 22px; }
        &:nth-child(10) i { font-size: 22px; }
        &:nth-child(11) i { font-size: 26px; }
    }

    i { color: $text-color; }
    .btn-menu-on-small {
        padding-top: 0;
        display: none;
    }
    .menu-on-small { display: none; }


    @media all and (max-width: 1700px) {
        .btn-menu-on-small { display: block; }
        .menu-on-small {
            width: 33.33%;
            background-color: white;
            position: absolute;
            top: 52px;
            right: 0;
            padding: 0;
            border-left: 1px solid $border-color;
            border-right: 1px solid $border-color;
            z-index: 1;
            opacity: 0;
            transform: translateY(-100px);
            transition: transform 0.2s ease-out, opacity 0.2s ease-out;
            display: none;
            &.is-active { display: block; }
            &.is-visible {
                transform: translateY(0);
                opacity: 1;
            }

            a {
                border-bottom: 1px solid $border-color;
                display: block;
                &.is-active, &:hover { background-color: $background-color; }
            }
        }
    }
}

.menu-label {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: normal;
    letter-spacing: 0;
}

.menu-list {
    .hover-anchor {
        opacity: 0;
        visibility: hidden;
        transition: .25s opacity, .25s visibility;
    }

    a {
        font-size: 12px;
        letter-spacing: 0;

        span {
            color: inherit;

            i {
                color: inherit;
            }
        }

        &.level {
            display: flex;

            .level-item {
                justify-content: initial;
            }
        }

        &:hover,
        &.is-active {
            .hover-anchor {
                opacity: 1;
                visibility: visible;
            }
        }

        &.is-active {
            background-color: $accent;
        }
    }
}
