.button {
    color: $text-color;
    font-size: $font-size;
    font-weight: 600;
    height: 26px;
    border-radius: $border-radius;

    &.is-50 {
        opacity: .5;
    }

    &.is-in-row {
        height: 30px;
        border-bottom: 0;
        border-top: 0;
    }

    &.is-success {
        background: $green;

        &:hover {
            background: darken($green, 5%);
        }

        &[disabled] {
            background: lighten($green, 20%)
        }
    }

    &.is-cancel {
        color: $gray-dark;
    }

    &.is-primary {
        background: $blue-dark;

        &:hover {
            background: darken($blue-dark, 5%);
        }

        &[disabled] {
            background: lighten($blue-dark, 20%)
        }
    }

    &.is-success,
    &.is-primary {
        color: white;
        font-weight: 600;
        border-color: $button-border-color;

        &:hover {
            box-shadow: none;
            border-color: $button-hover-border-color
        }
    }

    &.is-danger {
        color: white;
        font-weight: 600;
        border-color: $pink_dark;
        background: $pink_dark;

        &:hover {
            box-shadow: none;
            border-color: darken($pink_dark, 5%);
            background: darken($pink_dark, 5%)
        }
    }

    &.is-transparent {
        background: transparent;
    }

    &.is-gray {
        background-color: $gray-lighter;

        &:hover {
            background: darken($gray-lighter, 5%);
        }

        &[disabled] {
            background: lighten($gray-lighter, 20%)
        }
    }

    &.is-active {
        background: $blue-active;
        color: white;
    }

    &.is-dashed {
        border: 1px dashed $gray-darker;
        background: $background-color;
        font-weight: 600;

        &:hover {
            background: darken($background-color, 5%);
        }
    }

    &.is-large {
        padding-top: 3px;
        font-size: 13px;
        height: 28px;
        border-radius: $border-radius;
        i { margin-top: 2px; }
    }

    &.is-larger {
        padding-top: 3px;
        font-size: 13px;
        height: 30px;
        border-radius: $border-radius;
        i { margin-top: 2px; }
    }

    &.is-smaller {
        font-size: $font-size;
        height: 20px;
        border-radius: $border-radius;
        padding-top: 0;
        padding-bottom: 0;

        i.material-icons {
            font-size: 14px;
        }
    }

    &.is-small {
        font-size: $font-size;
        height: 18px;
        border-radius: $border-radius;
        padding-top: 0;
        padding-bottom: 0;
    }

    &.is-fullwidth {
        display: block;
    }

    & > * {
        color: inherit;
    }

    &:not(.is-icon) > * {
        margin-right: 5px;
    }

    &.is-icon > * {
        margin-right: 0 !important;
    }

    &:hover { border-color: $button-hover-border-color; }

    &.is-headline {
        height: 35px;
        background: #eee;
        width: 100%;
        text-align: center;
    }

    &.is-65 {
        min-width: 65px;
    }

    &.is-75 {
        min-width: 75px;
    }

    &.is-120 {
        min-width: 120px;
    }

    &.is-150 {
        min-width: 150px;
    }

    &.is-190 {
        min-width: 190px;
    }

    &.is-max-150 {
        max-width: 150px;
    }

    &.is-max-130 {
        max-width: 130px;
    }

    &.is-max-80 {
        max-width: 80px;
    }


    &.is-300 {
        min-width: 300px;
    }

    &.is-230 {
        min-width: 230px;
    }

    &.btn-select-addition {
        height: 30px;
        width: 30px;
        border-left: 0;
        i { margin: 0;}
    }

    &.is-icon-button {
        margin-left: 5px;
        padding:0;
        width: 30px;
        height:30px;
        i { margin: 0;}
    }
}


// Button groups
.buttons {
    position: relative;

    &.m-t { margin-top: 10px }

    .button      { margin-bottom: 0; }
    &:last-child { margin-bottom: 0; }

    // Grouped together
    &.has-addons {
        display: inline-flex;
        flex-wrap: nowrap;

        &:not(:last-child) {
            margin-bottom: 1rem;

            .level & {
                margin-bottom: 0;
            }
        }

        .button {
            border: 0;
            height: 26px;
            margin-left: -1px;
            border: 1px solid $button-border-color;
            &:first-child {
                margin-left: 0;
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
            }
            &:last-child {
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
            &:hover { border: 1px solid $button-hover-color; }

            &.is-read-only {
                cursor: text;
            }

            &.is-larger {
                height: 30px;
            }

            &.is-large {
                height: 28px;
            }

            &.is-small {
                height: 22px;
                padding-left: 7px;
                padding-right: 7px;
            }

            &.is-in-table {
                height: 30px;
                padding-left: 7px;
                padding-right: 7px;
                border-top: 0;
                border-bottom:0;
                &:nth-child(1) {
                    border-left:0
                }
                &:last-child {
                    border-right: 0
                }
            }

            &.is-tiny {
                height: 22px;
                padding-left: 2px;
                padding-right: 2px;

                i.material-icons {
                    font-size: 14px;
                }
            }
        }

        .button:not(:last-child) { margin-right: 0; }

        &.is-dashed {
            border: 1px dashed $gray-darker;

            .button {
                border: 0;

                &:not(:last-child) {
                    margin-right: 0;
                    border-right: 1px dotted $gray-darker;
                }
            }
        }

        &.is-fullwidth {
            display: flex;

            .button {
                flex: 1;
            }
        }


        &.toggle-switch {
            .button.is-primary { background: $blue-active; }
            .button {
                i {
                    margin-right: 0;
                }
            }
        }


    }

    .level & { margin-bottom: 0; }

    .buttons-menu {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 100%;
        margin-top: 10px;
        padding: 3px 0;

        overflow: hidden;
        border-radius: $border-radius;
        color: white;
        background: $blue-dark;

        a {
            color: white;
            padding: 2px 5px;
            padding-right: 15px;
            display: block;

            &:hover {
                background: lighten($blue-dark, 5%);
            }
        }
    }
}



button.is-delete,
a.is-delete,
.btn-delete {
    min-width: 17px;
    position: relative;
    i { color: $button-color; }
    &:hover i { color: $red; }
    &.is-loading {
        &:after {
            content: '';
            width: 1em;
            height: 1em;
            position: absolute;
            top: 3px;
            left: 1px;
            animation: spinAround .5s infinite linear;
            border: 2px solid $button-color;
            border-radius: 290486px;
            border-right-color: transparent;
            border-top-color: transparent;
            display: block;
        }
        i { display: none; }
    }
}

.btn-edit {
    i { color: $button-color; }
    &:hover i { color: $blue; }
}



/*
    Found in /orders/1/documents
*/
.bp-add-btn {
    margin: 1px 0 0 4px;
    i { color: $button-color; }
    &:hover i { color: black; }
}



/*
    Found in /orders/1 (lock draggable)
*/
.btn-single-toggle {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    transition: background-color 0.2s ease-out;
    color: $text-color;

    i {
        color: $text-color;
        transition: color 0.2s ease-out;
        margin: 0;

        &.fa {
            font-size: 12px !important;
        }

        &.material-icons {
            font-size: 14px;
            vertical-align: -2px;
        }
    }

    .btn-single-toggle-text {
        font-size: 8px;
        justify-content: center;
    }

    &.is-active {
        background-color: $blue-active;
        color: white;
        i { color: white; }
    }

    &:hover, &.is-active:hover {
        background-color: $blue-dark;
        color: white;
        i { color: white; }
    }

    &.has-border {
        border:1px solid $border-color;
    }

    &.is-large {
        width: 26px;
        height: 26px;

        i.fa {
            margin: 0 !important;
            vertical-align: middle;
        }
    }
}


/*
    Found in /clients/1 (under KONTAKTDATEN)
*/
.is-email-link, .is-link {
    color: $link-color;
    cursor: pointer;
    &:hover {
        color: $link-color;
        text-decoration: underline;
    }
}
