// Bulma border radius override
.field.has-addons .control:last-child .button,
.field.has-addons .control:last-child .input,
.field.has-addons .control:last-child .select select {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}
.prismCodeEditor {
    .number {
        -webkit-box-align: unset;
        -ms-flex-align: unset;
        align-items: unset;
        background-color: unset;
        border-radius: unset;
        display: unset;
        font-size: unset;
        height: unset;
        -webkit-box-pack: unset;
        -ms-flex-pack: unset;
        justify-content: unset;
        margin-right: unset;
        min-width: unset;
        padding: unset;
        text-align: unset;
        vertical-align: unset;
    }

    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #EAEAEA;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    line-height: 1.5;
    padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
    outline: none;
}

.is-table-form {
    table {
        width: 100%;

        td,
        th {
            vertical-align: middle;
            padding-top: 0;
        }

        th {
            padding-right: 30px;
            width: 1px;
            white-space: nowrap;
        }

        td {
            hr {
                margin: 0;
            }

            height: 30px;
        }

        tr:not(:last-child) {
            td,
            th {
                padding-bottom: 7px;
            }
        }

        .mr {
            margin-right: 10px;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-bottom: 0;
            border-bottom: 1px solid $gray-light;
            padding-bottom: 5px;
            margin-top: 5px;
        }
    }
}

.label {
    font-size: $font-size;
    font-weight: $font-weight-normal;
    color: $text-color;

    .material-icons, .fa {
        margin-right: 10px;
    }
}


.input,
.select select,
.textarea {
    height: 30px;
    border: 1px solid $input-border-color;
    border-radius: $border-radius;
    transition: border-color 0.2s ease-out;
    color: $text-color;
    font-size: $font-size;

    &.is-shorter { height: 26px; }
    &.is-readonly { color: $gray-dark }
}
.input, .textarea { box-shadow: none; }

.input {
    padding: 0 5px;
    &.is-large {
        width: 400px;
        font-size: $font-size;
    }

    &.is-tiny   { width: 30px; }
    &.is-tiny-small   { width: 50px; }
    &.is-small  { width: 100px; }
    &.is-smaller { width: 150px; }
    &.is-date {width: 80px; text-align: center}
    &.is-160 { width: 160px; }
    &.is-medium { width: 200px; font-size: $font-size; }
}


.radio,
.checkbox {
    font-size: $font-size;

    input {
        vertical-align: middle;
        margin-right: .75em;
    }

    &.is-nowrap {
        white-space: nowrap;;
    }
}


.checkboxes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 26px;
    padding-top: 5px;

    .checkbox {
        margin-bottom: 5px;
        margin-right: 10px;

        input {
            margin-right: 5px;
        }
    }

    &.columns {
        margin: 0;
        padding: 0;

        .column {
            padding: 0;
        }
    }
}


.select {
    width: 100%;

    select:not([multiple]) {
        padding-right: 15px;
    }

    &:not(.is-multiple) {
        select { height: 30px; }
        &.is-shorter, &.is-shorter select { height: 26px; }

        &::after {
            border-color: $gray-light;
            z-index: 0;
            transition:border-color 0.2s ease-out;
            margin-top: -6px;
            right: .5em;
        }

        &:hover::after {
            border-color: $text-color;
        }
    }

    select {
        width: 100%;

        option[disabled]:first-child {
            display: none;
        }
    }

    &.is-full-date {
        &, select { width: 93px; }
    }

    &.is-tiny   { width: 40px; }
    &.is-small  { width: 100px; }
    &.is-medium { width: 200px; font-size: 16px; }
}


.select select:active, .select select:focus,
.input:active, .input:focus,
.textarea:active, .textarea:focus {
    border-color: $input-hover-border-color;
    box-shadow: none;
}


.input-price {
    background: white;

    &.is-darken {
        background: $rowHeading;
    };

    border: 1px solid $input-border-color;
    border-radius: $border-radius;

    &.field.has-addons {
        .control .button.is-static {
            border: 0;
            background: 0;
            height: 100%;
            line-height: normal;
            color: $gray;
        }

        .control .select select {
            border-right: 0;
        }

        .control .input {
            padding: 0 5px;
            background: 0;
            border: 0;
            border-radius: 0;
            text-align: right;
        }

        &.has-suffix {
            .control .input {
                text-align: left;
            }
        }
    }

    &.field.has-addons .control + .control .input {
        border-radius: 0 $border-radius $border-radius 0;
    }

    &.is-small {
        width: 75px;
    }

    &.is-smaller {
        width: 110px;
    }

    &.is-160 {
        width: 160px;
    }

    &.is-medium-small {
        width: 150px;
    }

    &.is-medium {
        width: 200px;
    }
}


/* CUSTOM FIELDS */

// Field with ICON
.field {
    font-size: $font-size;

    &.has-icon {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
            margin-right: 5px;
            flex-grow: 0;
            flex-shrink: 0;
            justify-content: flex-start;
            width: 1rem;

            &.has-two {
                width: 2.5rem;

                & > :first-child {
                    margin-right: 0.2rem;
                }
            }
        }

        .control {
            flex: 1 1 100%;
        }
    }
}


// FLATPICKR - date picker
input.flatpickr-input {
    width: 100%;
    max-width: 100%;

    &.flatpickr--range { min-width: 200px; }
    &.is-tiny  { width: 25px; }
    &.is-small { width: 200px; }
}


.flatpickr-small {
    width: 180px;

    .flatpickr-input { width: 70px; }
}


.flatpickr-combined {
    display: flex;
    flex-direction: row;
    align-items: center;

    .flatpickr-input { max-width: 100px; }


    .flatpickr-input {
        max-width: 100px;

        &.is-small {
            max-width: 80px;
        }

    }

    > span {
        margin-left: 5px;
        margin-right: 5px;
    }
}


.control.has-icons-left .icon, .control.has-icons-right .icon {
    width: 26px;
    height: 26px;
    font-size: 12px;
    i { color: inherit; }
}

// TipTap editor
.tiptap {

    &.is-alone {
        border-top: 2px solid $accent;
        box-shadow: $box-shadow;
    }

    &.is-not-alone {
        border: 1px solid $gray-light;
    }


    border-radius: $border-radius;

    background: white;

    &.is-disabled {
        background: #f5f5f5;
    }

    &.resizeable {
        .tiptap-content {
            resize: vertical;
            overflow: hidden;
        }
    }

    &.is-fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99999;
    }

    &.is-fullwidth {
        width: 100%
    }

    &.is-smaller {
        .tiptap-content {
            height: 100px;
        }
    }

    &-menu {

        &.is-not-alone {
            border-bottom: 1px solid $gray-light;
        }

        position: relative;
        z-index: 0;
        background-color: $background-color;
        border-radius: $border-radius $border-radius 0 0;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        button {
            width: 26px;
            height: 30px;

            &:hover {
                background-color: $gray-lighter;
            }

            &.is-active {
                background-color: $gray-light;
            }

            span {
                font-weight: bold;
                font-size: 12px;
            }
        }

        .pickr {
            width: 30px;
            height: 30px;
            display: inline-block;
            padding: 5px;
            font-size: 0;

            .pcr-button {
                height: 20px;
                width: 20px;
            }
        }

        .tiptap-submenu {
            display: none;
            background-color: $gray-light;

            &.is-active {
                display: block;
            }

            button:hover {
                background-color: $gray-light;
            }
        }

        form {
            background-color: $gray-lighter;
            padding: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .input {
                flex: 1;
            }
        }
    }

    &-content {
        position: relative;
        z-index: 0;
    }

    &-content {
        height: 150px;
        padding: 10px;

        .ProseMirror {
            min-height: 100%;

            ul { list-style: disc; }
            ol { list-style: decimal; }
            ol, ul {
                list-style-position: outside;
                margin-left: 1.8em;
                li {
                    display: list-item ;
                }
                p {
                    display: inline;
                }
            }

            table {
                border: 1px solid $gray;
                width: 100%;

                th, td {
                    border: 1px solid $gray;
                    padding: 5px;
                }
            }

            a {
                color: $link-color;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        > pre {
            min-height: 150px;
            padding: 10px;
        }
    }
}


// Pell (editor)
.pell, .quillWrapper {
    border-top: 2px solid $accent;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    .pell-actionbar, .ql-toolbar {
        background-color: $background-color;
        border-radius: $border-radius $border-radius 0 0;
    }
    .pell-content {
        height: 150px;
        font-size: $font-size;
        background: white;

        ul { list-style: disc; }
        ol { list-style: decimal; }
        ol, ul { list-style-position: inside; }
    }

    &.is-larger {
        .pell-content {
            height: 300px;
        }
    }

    &.is-smaller {
        .pell-content {
            height: 100px;
        }
    }

    &.resizeable {
        .pell-content {
            resize: vertical;
        }
    }
}

.pell.is-flex {
    display: flex;
    flex-direction: column;
    height: 100%;

    .pell-content {
        flex: 1;
        min-height: 150px;
    }
}

.pell.is-fullwidth {
    width: 100%;
}

div.ql-editor { font-size: 14px; }
.quillWrapper .ql-container { font-family:$font-family-primary, 'Helvetica Neue', Helvetica, Arial, sans-serif; }
.quillWrapper .ql-snow .ql-picker.ql-font { width: 190px }

.ql-font span[data-value="open-sans"]::before {
    font-family: "Open Sans";
    content: "Open Sans" !important;
}

.ql-font span[data-value="open-sans-light"]::before {
    font-family: "Open Sans Light";
    content: "Open Sans Light" !important;
}
.ql-font-open-sans-light {
    font-family: "Open Sans Light";
}

.ql-font span[data-value="open-sans-semi-bold"]::before {
    font-family: "Open Sans Semibold";
    content: "Open Sans Semibold" !important;
}
.ql-font-open-sans-semi-bold {
    font-family: "Open Sans Semibold";
}


// Colorpicker
.pickr .pcr-button {
    height: 24px;
    width: 24px;
}


/* ------------------------------------------ *\
    Custom styling
\* ------------------------------------------ */
.z-2 {
    z-index: 2;
}

.z-3 {
    z-index: 3;
}

.z-4 {
    z-index: 4;
}

.z-5 {
    z-index: 5;
}

.multiselect {

    font-size: $font-size;
    min-height: 30px;

    &__tags {
        min-height: 30px;
        padding: 0 29px 0 0;
        border-color: $input-border-color;
        transition: border-color 0.2s ease-out;
    }

    &:hover &__tags { border-color: $input-hover-border-color; }

    &:hover &__copy { opacity: 1}

    &:hover &__select:before { opacity: 0}


    &__tag {
        margin: 3px;
        font-size: $font-size;
        padding-right: 20px;
        background: $multiselect-select;

        &-icon {
            width: 20px;
            line-height: 19px;
            border-radius: 0;
            display: flex;
            padding-left: 5px;
            align-items: center;
        }

        &-copy-icon {
            color: white;
            margin-right: 5px;
            font-size: 14px;
        }

        span {
            color: $multiselect-select-font;
            max-width: 200px;
            overflow: hidden;
            display: inline;
            text-overflow: ellipsis;
        }
    }

    &__input,
    &__single,
    &__placeholder {
        margin-bottom: 0;
        height: 28px;
        min-height: 28px;
        line-height: 28px;
        font-size: $font-size;
        padding-top: 1px;
        padding-bottom: 1px;
        border: 0;
        background: 0;
        margin-bottom: 0;
        padding-left: 6px;
        overflow: hidden;
    }

    &__tags, &__input, &__single { border-radius: $border-radius; }

    &__select {
        width: 24px;
        height: 28px;
        padding: 4px 6px;

        &:before {
            top: 75%;
            opacity: 1;
        }
    }

    &__option {
        min-height: 26px;
        padding: 4px;
        font-weight: $font-weight-normal;
        width: 100%;

        &--selected {
            background: $multiselect-selected;
            span, strong {
                color: $multiselect-selected-font;
            }
        }
        &--highlight {
            background: $multiselect-select;
            span, strong {
                color: $multiselect-select-font;
            }
        }

        &::after {
            min-height: 26px;
            padding: 4px;
            font-size: 12px;
            line-height: normal;
            display: none;
        }
    }

    &__content { font-weight: $font-weight-normal; }

    &.is-shorter {
        min-height: 26px;

        .multiselect__input,
        .multiselect__single,
        .multiselect__placeholder {
            height: 24px;
            min-height: 24px;
            line-height: 24px;
        }
        .multiselect__select { height: 24px; }
        .multiselect__tags {height: 26px;}
    }

    &.is-very-shorter {
        min-height: 20px;

        .multiselect__input,
        .multiselect__single,
        .multiselect__placeholder {
            height: 18px;
            min-height: 18px;
            line-height: 18px;
        }
        .multiselect__select { height: 18px; }
        .multiselect__tags {height: 20px;}
        .multiselect__spinner {height: 18px;}
    }

    &.is-normal {
        min-height: 32px;

        .multiselect__input,
        .multiselect__single,
        .multiselect__placeholder {
            height: 30px;
            min-height: 30px;
            line-height: 30px;
        }
        .multiselect__select { height: 30px; }
        .multiselect__tags {height: 32px;}
    }

    &.is-gapless {
        .multiselect__tags {
            border-left: 0;
            padding-right: 0;
        }
    }
    @media (min-device-width: 700px) {
        &.is-medium-desktop  { width: 180px; }
    }

    &.is-medium  { width: 180px; }
    &.is-smaller { width: 140px; }
    &.is-smaller-small { width: 120px; }
    &.is-small   { width: 100px; }
    &.is-large   { width: 400px; }
    &.is-very-large {width: 500px;}
    &.is-larger  { width: 300px; }
    &.is-small-tiny    { width: 75px; }
    &.is-tiny    { width: 50px; }

    &__spinner {
        width: 25px;
        height: 24px;
        border-radius: $border-radius;
    }

    &--active .multiselect__tags {
        border-color: $gray-dark;
        box-shadow: none;
    }

    // Used for (dynamically) searching orders
    &.is-order-select {
        min-width: 470px;

        .multiselect__tag { margin-bottom: 0; }
        .multiselect__option--highlight,
        .multiselect__option--highlight:after { background-color: $background-color; }

        .multiselect__content {
            .multiselect__option--highlight,
            .multiselect__option--highlight span,
            .multiselect__option--highlight:after { color: $text-color; }

            .multiselect__option {
                width: 100%;
                display: flex;
                align-items: center;
            }

            .multiselect__option > * {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .option__id, .option__name { color: $text-color; display: inline-block; }
            .option__id   { width:  80px; flex-shrink: 0; flex-grow: 0 }
            .option__name { flex: 1; }
            .option__destination { width: 160px; flex-shrink: 0; flex-grow: 0 }
        }
        .multiselect__option--highlight.multiselect__option--selected {
            background-color: $multiselect-deselect;
            &, span, i, strong { color: $multiselect-deselect-font; }
        }
    }

    &__clear {
        position: absolute;
        right: 25px;
        height: 24px;
        width: 24px;
        display: block;
        cursor: pointer;
        z-index: 0;

        &:before { transform: rotate(45deg); }
        &:after { transform: rotate(-45deg); }

        &:after,
        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 2px;
            height: 12px;
            background: #aaa;
            top: 8px;
            right: 4px;
        }
    }

    &__copy {
        position: absolute;
        right: 4px;
        top: 4px;
        cursor: pointer;
        opacity: 0;
    }


    .multiselect-crud-link {
        width: 30px;
        height: 100%;
        position: absolute;
        top: -1px;
        right: -1px;
        align-items: center;
        display: flex;
        i {
            width: 29px;
            margin-left: 2px;
            color: $button-color;
            text-align: center;
        }
    }
}

.multiselect.is-hotel-search {
    .multiselect__option {
        min-height: 36px;
        align-items: center;
        display: flex;

        .rating { margin-right: 5px; }
        .rating__circle { margin-bottom: 0; }
        h1 {
            margin-bottom: 0;
            font-weight: $font-weight-normal;
        }
        .stars .material-icons {
            min-width: 14px;
            font-size: 12px;
        }
    }
}


.color-switch {
    display: flex;
    flex-direction: column;
    margin-right: 5px;

    .tag {
        border-radius: 0;
        margin-right: 0;
        height: 10px;
        padding: 6px;
    }
}



// Error wrapper
.error-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: space-between;

    > .control {
        flex: 1
    }

    .v-popover {
        flex-grow: 0;
        flex-shrink: 0;
        margin-left: 10px;

        .trigger i.material-icons {
            color: $red;
        }
    }
}
.hover-upload-area:hover {
    background-color: #ddd;
}
// Dropzone
.dropzone {
    .redesign-table,
    .flex-table {
        margin-bottom: 15px;
    }

    .dz-dropzone {
        background: #fff;
        border: 2px dashed $gray;
        border-radius: $border-radius;
        padding: 30px 15px;
        text-align: center;

        transition: .25s background-color;
        font-size: $font-size;
        cursor: pointer;
        &.is-in-row {
            height: 20px;
            display: flex;
            align-items: center;
            padding-bottom: 0;
            padding-top: 0;
        }
    }
    is-fullHeight {
        height: 100%;
    }



    &.dz-drag-hover .dz-dropzone,
    .dz-dropzone:hover{
        background: $background-color;
    }

    .dz-file-preview {
        margin-top: 15px;
        padding: 8px 10px;
        background: $background-color;
        border:1px solid $gray-light;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05),
            0 1px 2px rgba(0, 0, 0, 0.1);

        font-size: $font-size;
        position: relative;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .dz-progress {
            position: absolute;
            height: 2px;
            bottom: 0;
            left: 0;
            width: 100%;

            .dz-upload {
                width: 0;
                background: $blue;
                height: 2px;
                display: block;
                transition: .2s width, .2s opacity;
                opacity: 0;
            }
        }

        .dz-remove {
            display: none;
        }

        &.dz-processing:not(.dz-success) .dz-progress .dz-upload,
        &.dz-processing.dz-error .dz-progress .dz-upload {
            opacity: 1;
        }

        &.dz-processing.dz-success .dz-progress .dz-upload {
            background: $green;
        }

        &.dz-error {
            .dz-remove {
                display: block;
            }

            .dz-progress .dz-upload {
                background: $red;
            }
        }

        .dz-error-message span {
            color: $red;
            font-style: italic;
        }
    }
}

.help {
    color: $text-color;
    font-style: italic;
}

.list-select {
    max-height: 220px;
    position: relative;
    overflow-y: auto;
}

// Switch
.switch[type="checkbox"] {
    outline: 0;
    user-select: none;
    display: inline-block;
    position: absolute;
    opacity: 0;

    & + label {
        position: relative;
        display: block;
        font-size: 1rem;
        line-height: 1;
        height: 24px;
        padding-left: 3.5rem;
        padding-top: .2rem;
        cursor: pointer;

        &::before {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 3rem;
            height: 24px;
            border-radius: 12px;
            background: $gray;
            content: "";
        }

        i {
            display: block;
            position: absolute;
            top: .25rem;
            left: .25rem;
            width: 1rem;
            height: 1rem;
            transform: translate3d(0, 0, 0);
            border-radius: 50%;
            background: #fff;
            transition: all .25s ease-out;
            color: #fff;

            font-size: 12px;
            line-height: 1rem;
            text-align: center;
        }
    }

    &:checked + label{
        &::before {
            background: $accent
        }

        i {
            left: 1.625rem;
            color: $accent;
        }
    }
}

.dropzone {
    position: relative;

    .dz-area {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#fff, .75);
        color: $text-color;
        opacity: 0;
        visibility: hidden;
        transition: .25s opacity, .25s visibility;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
    }

    &.dz-drag-hover {
        .dz-area {
            opacity: 1;
            visibility: visible;
        }
    }

    &.dropzone--images-tab {
        min-height: 500px;
        height: 100%;
    }
}

.gb-flag {
    margin-right: 5px;
    &.is-selected {
        border-bottom: 2px solid $accent;
    }
}
