
.color-picker {
  width: 1.7rem;
  height: 1.37rem;
  margin-top: 5px !important;
}

.ProseMirror {
  background-color: white;
  padding: 1rem 1rem !important;
  overflow: hidden;
  min-height: 10rem;
  height: 100%;
  resize: vertical;
}
.warnings {
    width: 100%;
    color: red;
    z-index: 2;

    > :first-child {
        border-top: 1px solid red;
        border-bottom: 1px solid red;
    }
    & > .warnings:nth-child(2) {
        border-bottom: 1px solid red;
    }
}
.has-extraLines-warnings {
    border: 1px solid red !important;
}
.is-fullscreen{
  .tiptapDiv{
    .ProseMirror{
      resize: none !important;
    }
  }
}

.is-disabled {
    .tiptapDiv {
        .ProseMirror {
            background: #f5f5f5;
        }
    }
}

.menu-items {
  padding: 0rem 0.3rem;
}

.tiptapDiv {
  height: unset !important;
}

.tiptap {
  border: 1px solid $border-color;
}
.is-alone{
    box-shadow: $box-shadow;
    border: none;
}
.ProseMirror {
  a {
    color: #1e43e9;
    text-decoration: underline;
  }
    color: blue;


    pre {
    color: black;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }

    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #F98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #FBBC88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #B9F18D;
    }

    .hljs-title,
    .hljs-section {
      color: #FAF594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70CFF8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
}

.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      >* {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

.ProseMirror {
  // >*+* {
  //   margin-top: 0.75em;
  // }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ol,
  ul {
    list-style-position: outside;
    margin-left: 1.8em;

    li {
      display: list-item;
    }

    p {
      display: inline;
    }
  }
}
