.gmap-autocomplete input {
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    padding: 8px 12px;
    font-size: 13px; /* Smaller font size */
    font-family: "Google Sans",Roboto,Arial,sans-serif; /* Use a clean, sans-serif font */
    font-weight: 400;
    color: #1f1f1f;
    border: 1px solid #e3e3e3; /* Thinner, light gray border */
    border-radius: 16px; /* More rounded corners */
    box-shadow: none; /* No additional shadow by default */
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    outline: none; /* Remove blue outline */
}

.gmap-autocomplete input:focus {
    border-color: #4285f4; /* Google Maps blue */
    box-shadow: 0 0 4px rgba(66, 133, 244, 0.5); /* Subtle blue glow */
}

.gmap-autocomplete input::placeholder {
    color: #999; /* Slightly lighter placeholder color */
}

.gmap-autocomplete input:hover {
    border-color: #b3b3b3; /* Slightly darker gray on hover */
}

.gmap-autocomplete {
    position: absolute;
    top: 100px; /* Distance from the top of the map */
    left: 40px;
    display: flex;
    width: 100%; /* Adjust width */
    max-width: 300px;
    background: white;
    border-radius: 20px;

    z-index: 0; /* Ensure it's above the map */
    padding: 0px;
}

.gmap-marker-label {
    margin-top: 15px
}
