.sb-aside {
    width: 450px;
    overflow: visible;
    overflow-y: auto;
    background: rgb(239, 239, 239);
    border-top: 2px solid $accent;
    height: 100%;
    position: fixed;

    &.is-right {
        right: 0;
    }

    &.is-left {
        left: 0;
    }

    top: 0;
    bottom: 0;
    z-index: 50;
    border-left: 1px solid $gray;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    transform: translateY(0);
    transition: all 0.2s ease-out;
    opacity: 1;

    .sb-wrapper {

        & > header {
            height: auto;
            background-color: $gray-lighter;
            margin-bottom: 0;
            padding: 10px 10px 10px;
            border-bottom: 1px solid $border-color;
            border-radius: $border-radius $border-radius 0 0;
            color: $text-color;
            font-weight: 600;
            font-size: 13px;
            text-align: center;
            justify-content: center;

            h1 {
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
            }

            .close {
                position: absolute;
                top: 10px;
                right: 15px;
                i {
                    color: $text-color;
                    font-size: 20px;
                }
                &:hover i {
                    color: #ccc;
                    font-weight: bold;
                }
            }
        }

        .sb-content {
            padding: 15px;
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 50px);


            .ai-question {
                right: 0;
                position: relative;
                border: $border-color solid 1px;
                background: $gray-lightest;
                padding: 10px;
                border-radius: 20px;
            }

            .ai-response {
                padding: 10px;
                border-radius: 20px;
                border: $border-color solid 1px;


                textarea {
                    background: rgb(239, 239, 239);
                    border: 0;
                };
            }

            .ai-prompt {
                margin-top: auto;

                textarea {
                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #c1c1c1;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background: #a8a8a8;
                    }
                    &::-webkit-scrollbar-button {
                        display: none;
                    }
                    padding-right: 4rem;
                }

                .send-button {
                    right: 15px;
                    display: inline-flex;
                    justify-content: center;
                    height: 100%;
                    align-items: center;
                    position: absolute;
                    top: 0;

                    .btn-single-toggle {
                        width: 38px;
                        height: 38px;
                    }

                    i {
                        font-size: 24px;
                    }

                }
            }
        }
    }
}
