.pdf-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    min-height: 228px;

    &.is-empty {
        width: 100%;
        border: 1px dashed $gray;
    }

    h3 {
        word-break: break-word; /* For modern browsers */
        overflow-wrap: break-word; /* Ensures words wrap properly */
        white-space: normal; /* Allows text to wrap */
        min-height: 45px
    }

    .canvas {
        border: 1px solid #ccc;
    }
}

.drag-ghost {
    .hide-on-drag {
        visibility: hidden;
    }
}
