.modal-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    transition: opacity 0.2s ease-out;
    z-index: 2;
}

.modal-aside {
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    &.fade-enter-active .modal-content,
    &.fade-leave-to .modal-content {
        transform: translateX(100%);
        opacity: 0;
    }

    .modal-content {
        width: 420px;
        max-height: none;
        height: 100%;
        background-color: white;
        position: absolute;
        top: 0;
        right: 0;
        padding: 62px 27px 12px 12px;
        z-index: 2;
        transform: translateX(0);
        transition: all 0.2s ease-out;
        overflow: visible;
        overflow-y: auto;
    }

    .buttons {
        padding-top: 20px;
        justify-content: space-between;
        display: flex;
        button {
            min-width: 150px;
        }
    }

    .modal-times {
        position: absolute;
        top: 10px;

        i {
            font-size: 30px;
        }
    }
}

.modal {
    height: 100%;
    cursor: default;
    display: block;

    /* &.fade-enter-active .modal-content, &.fade-leave-to .modal-content { */
    &.fade-enter .modal-content,
    &.fade-leave-to .modal-content {
        transform: translateY(-30%);
        opacity: 0;
    }

    &.fade-enter-to .modal-content,
    &.fade-leave .modal-content {
        transform: translateY(0);
        opacity: 1;
    }

    .modal-content {
        max-width: 85%;
        width: 85%;
        // height: 90%;
        background-color: white;
        position: relative;
        margin: 20px auto;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        transform: translateY(0);
        transition: all 0.2s ease-out;
        opacity: 1;
        z-index: 2;
        overflow: visible;

        & > header {
            height: auto;
            background-color: $gray-lighter;
            margin-bottom: 0;
            padding: 10px 30px 5px;
            border-bottom: 1px solid $border-color;
            border-radius: $border-radius $border-radius 0 0;
            color: $text-color;
            font-weight: 600;
            font-size: 13px;
            text-align: center;
            justify-content: center;

            h4 {
                margin-bottom: 0;
                font-size: 30px;
                font-weight: 300;
            }

            .close {
                position: absolute;
                top: 2px;
                right: 4px;
                padding: 5px 0;
                i {
                    color: #ccc;
                    font-size: 20px;
                }
                &:hover i {
                    color: $text-color;
                    font-weight: bold;
                }
            }
        }

        .modal-body {
            height: 790px;
            position: relative;
            padding: 20px 30px;
            overflow-y: scroll;

            iframe {
                width: 100%;
            }
        }

        footer {
            background-color: #f7f9fa;
            padding: 10px 15px;
            border-top: 1px solid #e5e5e5;
            border-radius: 0 0 $border-radius $border-radius;
            text-align: right;
            & > div {
                display: flex;
                justify-content: space-between;
            }
            button {
                min-width: 125px;
                font-weight: 600;
            }
        }
    }
}

.modal.is-small {
    .modal-content {
        width: 620px;
        &:before,
        &:after {
            content: none;
        }
    }

    header {
        color: $blue-dark;
        font-weight: 600;
        text-align: left;
    }
    .close {
        display: none;
    }
}

// FORM MODAL
.modal.is-form {
    .modal-content {
        width: 620px;

        display: flex;
        flex-direction: column;

        header {
            background: $background-color;
            padding: 7px 15px;

            p {
                font-size: 14px;
                color: $text-color;
            }
        }

        .modal-body {
            overflow-y: auto;
            flex: 1;
            padding: 15px;
        }
    }




    &.is-medium {
        .modal-content {
            width: 1000px;

            .search-day-concept {
                width: 995px;
            }
        }
    }

    &.is-large {
        .modal-content {
            width: 1400px;
        }
    }

    &.is-small {
        .modal-content {
            width: 500px;
        }
    }


    .images {
        .image {
            background: #fff;
            box-shadow: 0 0 5px rgba(#000, 0.1);
            margin-bottom: 5px;

            &.is-active {
                border: 2px solid $accent;
            }
        }
    }

    .title {
        border-bottom: 1px solid $gray-light;
        padding-bottom: 5px;
        margin-top: 5px;

        h1,
        h2,
        h3,
        h4,
        h5 {
            padding-bottom: 0;
            margin-top: 0;
            border: 0;
            padding-right: 5px;
        }
    }

    table {
        width: 100%;

        td,
        th {
            vertical-align: middle;
            padding-top: 0;
        }

        th {
            padding-right: 30px;
            width: 1px;
            white-space: nowrap;

            &.is-top {
                vertical-align: top;
                line-height: 26px;
            }
        }

        td {
            hr {
                margin: 0;
            }

            height: 30px;
        }

        tr:not(:last-child) {
            td,
            th {
                padding-bottom: 7px;
            }
        }

        .mr {
            margin-right: 10px;
        }

        &.properties {
            tr {
                td:not(:first-child) {
                    padding-left: 10px;
                }

                td:last-child {
                    white-space: nowrap;
                    text-align: right;
                    width: 1%;

                    i {
                        cursor: pointer;
                    }
                }
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-bottom: 0;
            border-bottom: 1px solid $gray-light;
            padding-bottom: 5px;
            margin-top: 5px;
        }
    }

    // SMALL styling for form elements
    .control.has-icons-left .icon,
    .control.has-icons-right .icon {
        width: 2em;
        height: 2em;
        font-size: 10px;
    }

    .field {
        display: flex;
        align-items: center;

        .ml {
            margin-left: 5px;
        }
    }

    &.is-image-form {
        .modal-content {
            width: 656px;
        }

        .map {
            width: 540px;
            height: 250px;
        }
    }
}

.image {
    & + .image-delete {
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px;
        background: rgba(#000, 0.5);
        color: #fff;
        cursor: pointer;
    }
}


.modal.is-bp-form {
    .modal-content {
        width: 640px;
    }
    &.is-large .modal-content {
        width: 960px;
    }
    &.is-fullwidth .modal-content {
        width: 90%;
    }
    &.has-scroll .modal-body {
        overflow-y: scroll;
        max-height: 80vh;
    }

    &:not(has-scroll) .modal-body {
        overflow-y: visible;
    }

    .modal-body {
        height: auto;
        overflow-y: auto;

        & > div {
            margin-bottom: 10px;
            align-items: flex-start;
            display: flex;

            label {
                width: 110px;
                flex: none;
                line-height: 24px;
                font-size: $font-size;
            }

            .input-price {
                width: 100%;
            }
            .textarea {
                min-width: 0;
            }
        }

        h4 {
            padding-bottom: 5px;
            border-bottom: 1px solid $gray-light;
        }
        .pell {
            width: 100%;
        }
        .pell-content {
            border-radius: 0 0 $border-radius $border-radius;
        }
    }
}

.modal.is-confirmation,
.modal.placeholder .modal.is-confirmation {
    .modal-content {
        width: 500px;
        header {
            display: none;
        }
        .modal-body {
            height: auto;
            background-color: white;
            padding: 20px 30px;
            overflow-y: visible;
            text-align: left;
        }
    }
    footer {
        display: block;
    }
}

.modal.y-overflow-visible {
    .modal-content {
        .modal-body {
            overflow-y: visible;
        }
    }
}
