@import '../clientSpecific/settings';
@import '../utilities/sizes';


.box .redesign-table .table-wrapper,
.is-user-dashboard .redesign-table .table-wrapper,
.panel .redesign-table .table-wrapper,
.crud .tab-addition .main .redesign-table .table-wrapper,
.main-panel .redesign-table .table-wrapper{
    // border:1px solid $gray-light;
    //box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05),
    //        0 1px 2px rgba(0, 0, 0, 0.1);
}

.is-table-form-headline {
    //used in data-export to create table like feeling with forms
    border: 1px solid #ddd;
    width: 100%;
    height: 26px;
    padding: 5px;
    display: flex;
    align-items: center
}

.redesign {
    &-table {
        &:not(.is-sub-table){
            border-top: 2px solid $accent;
        }
        position: relative;
        font-size: $font-size;
        width: 100%;
        display: block;
        &.has-transition {
            transition: padding 0.4s ease 0s;
        }

        .table-wrapper { background: $row; }

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        .is-sub-table {
            margin-bottom: 0;
        }

        .table-label {
            background: $accent;
            color: white;
            position: absolute;
            right: 0;
            top: 0;
            padding: 0 20px;
        }

        .is-white .row { background: white; }
        .is-light .row { background: $rowZebra }

        .is-zebra > .row {
            &:nth-child(2n):not(.is-editing) {
                background: $rowZebra;

                &:hover {
                    background: $rowHover
                }
            }
        }

        .content {
            padding: 10px;
        }

        .row {

            &.is-blue {
                background: $blue-dark;
                .cell {
                    color: white;
                    i {
                        color: white;
                    }
                }
            }


            display: flex;
            flex-wrap: nowrap;
            border-bottom: 1px solid $rowBorder;
            background: $row;
            align-items: center;
            transition:  .25s background;
            height: 32px;
            &.requestItemRow { opacity: 0.6; }
            &.has-blue-text {
                > .cell {
                    .input {
                        color: $link-color;
                    }
                }
            }

            &.has-subrows {
                height: auto;
                flex-wrap: wrap;
                border-bottom: 2px solid darken($rowBorder, 10%);
            }

            &.is-h-auto {
                height: auto;
                align-items: flex-start;

                > .cell {
                    min-height: 32px;
                }

                .input-wrapper {
                    height: 100%;
                    border: 1px solid $rowBorder;
                    border-top: 0;
                    border-bottom: 0;
                    background: white;

                    .input, .multiselect__tags {
                        border: 0
                    }
                }

                .self-stretch {
                    align-self: stretch;
                    border: 0;
                }

                .textarea {
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
            }

            .row {
                white-space: nowrap;
                width: 100%;
                background: 0;

                &:last-child {
                    border-bottom: 0;
                }
            }

            &.has-border {
                .cell:not(.is-actions) {
                    border-right: 1px solid $input-border-color;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }

            &.has-border-left {
                .cell:not(.is-actions) {
                    border-left: 1px solid $input-border-color;
                }
            }
            &.has-no-border {
                border: none;
            }





            &.is-head {


                .cell {
                    &.has-border-head {
                        border-right: 1px solid $input-border-color;
                        height: 100%;
                        display: flex;
                        align-items: center;
                    }
                }


                background: $headRow;

                font-weight: 600;

                .cell {
                    white-space: nowrap;

                    > i.material-icons,
                    > i.fa { color: $text-color !important; }
                    .btn-single-toggle.is-active i { color: white !important; }
                }

                &.is-subrow {
                    font-weight: normal;
                    height: 32px;
                }
            }

            &.is-selected {
                background: $pink_dark;
                white-space: nowrap;
            }

            &.is-light-red {
                background: $pink;
                white-space: nowrap;
            }

            &.is-selected-blue {
                background: $blue-lighter;
                white-space: nowrap;
            }

            &.is-editing { background: $editingRow; }
            &.is-white { background: #fff; }
            &.is-filters,
            &.is-head {
                min-height: 35px;
                height: unset;

                .cell:not(.is-actions) {
                    i.material-icons,
                    i.fa { margin-right: 5px; }
                }
            }

            &.is-header {
                padding: 10px;
                height: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;

                h2 { margin-bottom: 0; }
            }

            &.is-filters {
                padding: 0 10px;
                padding-right: 0;
                display: flex;
                flex-direction: row;
                align-items: center;

                display: flex;

                > * {
                    margin-right: 10px;
                    margin-bottom: 0;
                }

                > .input,
                > .select,
                > .multiselect,
                > i.fa,
                > i.material-icons {
                    max-width: 200px;
                    min-width: 25px;
                    &.is-500 { max-width: 500px; }
                    &.is-730 { max-width: 730px; }
                }

                > .combined {
                    display: flex;
                    align-items: center;

                    > span {
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }
            }

            &.is-headline {
                padding: 0 10px;
                height: 35px;
                background: $rowHeading;
                font-weight: 600;
                justify-content: space-between;

                &.is-small {
                    height: 32px;
                }

                &.is-darker {
                    background: $gray-lighter
                }

                .b-l {
                    border-left: 1px solid $gray;
                    padding-left: 10px;
                }

                .m-r {
                    margin-right: 5px;
                }
            }

            &.is-darken {
                background: $rowHeading;
            }

            .is-visible-action {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
            }

            .is-actions {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                max-width: 80px;


                :not(.btn-delete)  i.material-icons, :not(.btn-delete) i.fa {
                    color: $blue-dark;
                    transition: .25s color;
                    &:hover { color: $blue-active; }
                }

                .simple-menu {
                    button { height: 19px }
                    .simple-menu-content {
                        i.material-icons, :not(.btn-delete) i.fa {
                            color: white;
                        }
                    }
                }



                button { min-width: 17px; }

                i.material-icons,
                i.fa {
                    &.is-active {
                        color: $blue-active;
                    }
                }
            }

            &.is-faded {
                opacity: .5;
            }

            &:not([class*=' is']) {
                .cell {
                    &:not(.is-actions):not(.is-editable):not(.is-relative) {
                        @include ellipsis();
                        .v-popover {
                            @include ellipsis();
                        }
                    }
                }

                .is-actions {
                    opacity: 0;
                    transition: .25s opacity;
                }

                &:hover {
                    background: $rowHover;

                    .is-actions { opacity: 1; }
                }

                a {
                    color: $accent;

                    &:hover { text-decoration: underline; }
                }

                .cell:not(.is-actions):not(.is-icon-buttons) {
                    i.fa, i.material-icons{
                        color: $gray-dark;

                        &.is-button {
                            color: $blue-dark;
                            transition: .25s color;

                            &:hover { color: $blue-active; }
                        }
                    }
                }

                .stars i.material-icons { color: $accent !important }
            }
        }

        .is-sub-table {
            .row {
                &.is-head {
                    background: $subTableHeadRow;
                }
            }
        }

        .cell {
            flex-grow: 1;
            width: 10%;
            padding: 0 10px;
            // overflow: hidden;

            &.is-three-quarters { width: 75% }
            &.is-two-thirds { width: 66.6666% }
            &.is-half { width: 50% }
            &.is-one-third { width: 33.3333% }
            &.is-one-quarter { width: 25%; flex-grow: 0; }
            &.is-full { width: 100%; }

            &.is-four-fifths { width: 80% }
            &.is-three-fifths { width: 60% }
            &.is-two-fifths{  width: 40%;}
            &.is-one-fifth {width: 20%}
            &.is-one-tenth { width: 10%}
            &.is-thirty { width: 30%; flex-grow: 0}
            &.is-double { width: 20%; flex-grow: 0}
            &.is-fifteen { width: 15%; flex-grow: 0}


            &.is-right {
                text-align: right;
                justify-content: right;
            }


            &.has-text-centered {
                justify-content: center;
                .input {
                    text-align: center !important;
                }
                .multiselect {
                    justify-content: center;
                    text-align: center;
                }
                .multiselect__single {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            &.has-border {
                border-right: 1px solid $input-border-color;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &.has-border-left {
                    border-left: 1px solid $input-border-color;
            }

            &.has-red-text {
                span {
                    color: $red2;
                }
                .input {
                    color: $red2;
                }
            }

            @each $size in $sizes {
                &.is-#{$size}px-cell { width: #{$size}px; flex-grow: 0; flex-shrink: 0 }
            }
            &.is-days-cell { width: 220px; flex-grow: 0; flex-shrink: 0; }
            &.is-user-cell { width: 65px; flex-grow: 0; flex-shrink: 0;}
            &.is-small-cell { width: 65px; flex-grow: 0; flex-shrink: 0 }
            &.is-smaller-cell { width: 100px; flex-grow:0; flex-shrink: 0 }
            &.is-date-cell { width: 85px; flex-grow:0; flex-shrink: 0 }
            &.is-check-cell { width: 62px; flex-grow: 0; flex-shrink: 0 }
            &.is-icon-cell { width: 42px; flex-grow:0; flex-shrink: 0  }
            &.is-icon-date-cell { width: 125px; flex-grow:0; flex-shrink: 0 }
            &.is-small-icon-cell { width: 20px; flex-grow:0; padding:0}

            &.is-double-icon-cell { width: 73px; flex-grow: 0; }
            &.is-medium-cell { width: 150px; flex-grow: 0; flex-shrink: 0; }
            &.is-larger-cell { width: 200px; flex-grow: 0; }
            &.is-big-cell { width: 350px; flex-grow: 0; flex-shrink: 1; }
            &-is-auto-width {width: auto}


            &.is-narrow { width: 1px; }

            &.is-gapless {
                padding-left: 0;
                padding-right: 0;

                > .button.is-fullwidth {
                    width: 100%;
                    background: $background-color;
                    border: 0;
                    text-align: center;
                    height: 100%;

                    i.material-icons {
                        font-size: 22px
                    }
                }

                .input, .textarea {
                    &.no-left-border {
                        border-left: 0;
                    }
                    padding-left: 10px;
                    padding-right: 10px;
                }

                & + .is-gapless {
                    .input, .textarea .multiselect__tags {
                        border-left: 0;
                    }
                }
            }

            &.is-relative {
                position: relative;
                white-space: nowrap;
                height: 18px;

                .cell-content {
                    position: absolute;

                    .mr {
                        margin-right: 10px;
                    }

                    &.is-full {
                        left: 10px;
                        right: 10px;
                    }

                    &.v-popover {
                        width: 100%;
                        padding-right: 10px;

                        .trigger {
                            width: 100%;

                            .is-truncated {
                                display: block;
                            }
                        }
                    }
                }
            }

            &.is-sortable {
                cursor: pointer;

                &:after {
                    margin-left: 10px;
                    content: '\f0dc';
                    font-family: 'FontAwesome';
                    color: $gray-dark;
                }

                &.is-order-asc:after  { content: '\F160'; }
                &.is-order-desc:after { content: '\F161'; }
            }




            .input, .textarea {
                border-radius: 0;

                border: 1px solid $gray-light;

                border-top: 0;
                border-bottom: 0;
                height: 30px;
                box-shadow: 0 0 0 rgba(#fff, 0);
            }

            .sort-number {
                width: 30px;
                background: $accent;
                color: #fff;
                line-height: 30px;
                text-align: center;
                flex-grow: 0;
                flex-shrink: 0;
            }

            .bp-deadline {
                &.is-editable {
                    border-top: 0;
                    border-bottom: 0;
                    height: 30px;
                }
            }

            .badges {
                display: inline-flex;

                .badge {
                    display: block;
                    margin-left: 0;
                    margin-right: 5px;
                }
            }

            .btn-single-toggle {
                line-height: 20px;
            }

            .multiselect {
                min-height: 30px;

                &__tags {
                    border-top: 0;
                    border-bottom: 0;
                    border-color: $gray-light;
                    border-radius: 0;
                    height: 30px;
                }

                &__select {
                    height: 26px;
                }

                &__input {
                    height: 30px;
                    min-height: 30px;
                }
            }
        }

        &__footer {
            padding-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between
        }

        // Special items
        [data-bubble] {
            white-space: nowrap;

            &::before {
                background: $accent;
                color: white
            }
        }

        .rating {
            white-space: nowrap;
            display: flex;
            align-items: center;

            &__circle {
                margin-bottom: 0;
                margin-right: 5px;
            }

            &__value {
                font-size: 9px;
                width: 100%;
                text-align: center;
                background: white;
                border-radius: 50%;
            }
        }

        .tags {
            &:last-child { margin-bottom: 0; }

            .tag {
                &:not([class*=' is']) {
                    background: $accent;
                }

                padding: 1px 10px;
                line-height: normal;
                border-radius: 0;
                height: auto;
                margin-bottom: 0;
                font-size: 11px;
                line-height: 18px;

                &:not(:last-child) { margin-right: 3px; }

                &.is-icon {
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    text-align: center;
                    padding: 0;

                    i.material-icons,
                    i.fa,
                    span{ color: white !important }

                    i.material-icons,
                    i.fa { font-size: 10px; }

                    span { font-size: 8px; }
                }
            }
        }

        .bp-deadline {
            &::before {
                position: absolute;
                top: 50%;
                margin-top: -5px;
                left: 0;
                margin-left: -16px;
            }

            position: relative;
        }

        .user {
            width: 24px;
            height: 24px;
        }

        .indicator {
            display: inline-block;
            width: 18px;
            height: 18px;
            background: $gray-lighter;
            color: $text-color;
            line-height: 16px;
            text-align: center;
            border: 1px solid $gray;
            border-radius: 50%;
            font-size: 11px;

            &.is-red {
                color: white;
                background: $pink_dark;
                border-color: darken($pink_dark, 10%)
            }

            &.is-blue {
                color: white;
                background: $blue-active;
                border-color: darken($blue-active, 10%)
            }
        }

        &.is-gapless {
            border-top: 0;
            padding: 0;

            .table-wrapper {
                box-shadow: 0 0 0 rgba(black, 0);
            }
        }

        .status-progress {
            position: relative;

            .pie-chart {
                position: absolute;
                left: 0;
                top:50%;
                margin-top: -9px;
                width: 18px;
                height: 18px;

                + span {
                    padding-left: 24px;
                }
            }
        }

        .complexity {
            .pie-chart {
                position: relative;
                display: inline-block;
                margin-right: 4px;

                width: 18px;
                height: 18px;

                span {
                    position: relative;
                    left: 5px
                }

            }
        }
    }
}

.requests-count {
    width: 22px;
    height: 22px;
    background-color: white;
    margin: 0 5px 0 0;
    padding-bottom: 2px;
    border: 1px solid $border-color;
    border-radius: 50%;
    text-align: center;
    font-size: $font-size;
    font-weight: 600;
    display: inline-block;
    &:hover { border-color: $button-hover-border-color; }

    &.is-primary {
        background: $blue-dark;
        color: #fff;
        border-color: $blue-dark;
    }

    i {
        color: inherit !important;
        margin-right: 0 !important;
        width: 20px;
        height: 20px;
        line-height: 21px;
        text-align: center;
    }
}

.conditions-table {
    width: 100%;

    th {
        height: 35px;
        padding: 0 10px;
        background: $headRow;
        font-weight: 600;
        color: $text-color;
        vertical-align: middle;

        &.b-r {
            border-right: 1px solid darken($headRow, 5%);
        }
    }

    td {
        height: 32px;
        vertical-align: middle;
        border-bottom: 1px solid darken($headRow, 5%);
        background: white;

        &:not(:last-child) {
            border-right: 1px solid darken($headRow, 5%);
        }

        .input {
            border: 0;
            padding-left: 10px;
            padding-right: 10px;
            height: 30px;
            background: transparent;
        }

        .multiselect {
            &__tags {
                border: 0;
            }
        }
    }

    .is-small {
        width: 55px;

        .input {
            text-align: center;
        }
    }

    .is-narrow {
        width: 1%;
        padding-left: 10px;
        padding-right: 10px
    }

    .is-medium { width: 300px }
    .is-large { width: 300px }

    button {
        width: 100%;
        background: $background-color;
        height: 100%;

        i.material-icons {
            font-size: 22px
        }
    }
}

.edit-table {
    &.redesign-table {
        .row {
            &.is-head,
            &.is-headline {
                height: 32px;

                .cell {
                    padding: 0 6px;
                }
            }

            &:not(.is-head) {
                .cell {
                    &:not(.is-pure-text) {
                        padding-left: 0;
                        padding-right: 0;
                    }
                    &.is-actions {
                        padding-right: 6px;
                    }
                    &.is-ellipsis {
                        @include ellipsis();
                    }
                }

                &:not(.not-white) {
                    .cell {
                        background-color: white;
                    }
                }
            }
        }

        .cell {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-shrink: 0;


            &.is-centered {
                justify-content: center;
            }

            &.is-right {
                justify-content: right;
            }

            &.is-pure-text {
                padding: 0 6px;
            }

            height: 30px;

            //border-left: 1px solid $gray-light;

            &:last-child {
                border-right: 1px solid $gray-light;
            }

            .input-price, .input, .multiselect__tags {
                background-color: white;

                border: 0;
            }

            [data-prefix] {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                &::before {
                    content: attr(data-prefix);
                    width: 25px;
                    text-align: center;
                }
            }

            span[data-prefix] {
                padding-right: 5px;
            }
        }

        &.has-orange-inputs {
            .row {
                &:not(.is-head) {
                    .cell {
                        background-color: $subTableRow;
                        @each $size in $sizes {
                            &.is-#{$size}px-cell { width: #{$size}px; flex-grow: 0; flex-shrink: 0 }
                        }
                        .input-price, .input {
                            //background-color: #fef6ed;
                        }
                    }
                }
            }
        }

    }
}
.columns {
    &.has-min-width-150 {
        .column {
            input, .multiselect {
                min-width: 150px;
            }
        }
    }
}
